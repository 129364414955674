const decimal = (formatStyle: string, value: any, decimal: number) => {
  if (typeof decimal === "number" && value > 0) {
    const receiveValue = parseFloat(value);
    receiveValue.toFixed(decimal).replace(",", ".");
    return receiveValue.toLocaleString("pt-BR", {
      style: formatStyle,
      currency: "BRL",
      minimumFractionDigits: 2,
      maximumFractionDigits: decimal,
    });
  }
};

export default decimal;
