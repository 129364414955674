const cutText = (text: string, size: number): string => {
  let formattedText: string = text;

  if (typeof formattedText !== "object" && formattedText.length > size) {
    formattedText = `${text.substring(0, size)} ...`;
  }

  return formattedText;
};

export default cutText;
