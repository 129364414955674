import axios from "axios";
import { useToast } from "vue-toastification";

const toast = useToast();

const instance = axios.create({
  baseURL: import.meta.env.VITE_SMAUG_API,
  headers: {
    "Content-Type": "application/json",
    "Authorization": import.meta.env.VITE_SMAUG_TOKEN
  },
});

export default instance;