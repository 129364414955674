const formatPhone = (text) => {
  text = text.toString().replace(/\D/g, "");
  if (text.length === 10) {
    const format = text.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    return format;
  } else if (text.length === 11) {
    const format = text.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4");
    return format;
  } else {
    // Número de telefone inválido, retorna o texto original
    return text;
  }
};
export default formatPhone;
