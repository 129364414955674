import { useAuth } from "../stores/auth";

export default [
  {
    path: "/auth",
    redirect: "/auth/login",
    children: [
      {
        name: "login",
        path: "login",
        component: () => import("@/views/auth/Login.vue"),
      },
      {
        name: "registerStepOne",
        path: "registerStepOne",
        component: () => import("@/views/auth/RegisterStepOne.vue"),
      },
      {
        name: "registerStepTwo",
        path: "registerStepTwo",
        component: () => import("@/views/auth/RegisterStepTwo.vue"),
      },
      {
        name: "registerConfirmation",
        path: "registerConfirmation",
        component: () => import("@/views/auth/RegisterConfirmation.vue"),
      },
      {
        name: "ForgetPassword",
        path: "forget-password",
        component: () => import("@/views/auth/ForgetPassword.vue"),
      },
      {
        name: "resetPassword",
        path: "reset-password/:token",
        props: (route) => ({ query: route.query.q }),
        component: () => import("@/views/auth/ResetPassword.vue"),
      },
      {
        name: "emailConfirmation",
        path: "email-confirmation",
        props: (route) => ({ query: route.query.q }),
        component: () => import("@/views/auth/EmailConfimation.vue"),
      },
      {
        name: "confirmDelete",
        path: "confirmDelete",
        component: () => import("@/views/auth/ConfirmAccountDelete.vue"),
      },
      {
        name: "logout",
        path: "logout",
        component: () => {
          const auth = useAuth();
          auth.logoutUser();
        },
      },
    ],
  },
];
