<template>
  <div v-if="store.settings.mobile">
    <div class="bg-oderco-darker p-5">
      <div class="d-flex align-self-center align-items-center">
        <div>
          <svg
            @click="store.sidebar({ mode: 'open' })"
            v-if="store.settings.mobile"
            width="19"
            height="17"
            viewBox="0 0 19 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.0500031 1.74999C0.0500031 1.39195 0.192235 1.04857 0.445409 0.7954C0.698583 0.542226 1.04196 0.399994 1.4 0.399994H17.6C17.958 0.399994 18.3014 0.542226 18.5546 0.7954C18.8078 1.04857 18.95 1.39195 18.95 1.74999C18.95 2.10804 18.8078 2.45141 18.5546 2.70459C18.3014 2.95776 17.958 3.09999 17.6 3.09999H1.4C1.04196 3.09999 0.698583 2.95776 0.445409 2.70459C0.192235 2.45141 0.0500031 2.10804 0.0500031 1.74999ZM0.0500031 8.49999C0.0500031 8.14195 0.192235 7.79857 0.445409 7.5454C0.698583 7.29223 1.04196 7.14999 1.4 7.14999H17.6C17.958 7.14999 18.3014 7.29223 18.5546 7.5454C18.8078 7.79857 18.95 8.14195 18.95 8.49999C18.95 8.85804 18.8078 9.20141 18.5546 9.45459C18.3014 9.70776 17.958 9.84999 17.6 9.84999H1.4C1.04196 9.84999 0.698583 9.70776 0.445409 9.45459C0.192235 9.20141 0.0500031 8.85804 0.0500031 8.49999ZM0.0500031 15.25C0.0500031 14.892 0.192235 14.5486 0.445409 14.2954C0.698583 14.0422 1.04196 13.9 1.4 13.9H17.6C17.958 13.9 18.3014 14.0422 18.5546 14.2954C18.8078 14.5486 18.95 14.892 18.95 15.25C18.95 15.608 18.8078 15.9514 18.5546 16.2046C18.3014 16.4578 17.958 16.6 17.6 16.6H1.4C1.04196 16.6 0.698583 16.4578 0.445409 16.2046C0.192235 15.9514 0.0500031 15.608 0.0500031 15.25Z"
              fill="white"
            />
          </svg>
        </div>
        <div class="d-flex justify-content-center w-100">
          <img src="/assets/media/logo/ODEX-LOGO-MOBILE.png" alt="Odex Logo" />
        </div>
      </div>
    </div>
    <slot name="top">
      <div class="h2 fw-bold pt-4 mx-3 text-black">
        {{ title }}
        <span class="text-secondary fw-normal" style="font-size: 16px">
          <span v-if="subtitle"> / </span>
          {{ subtitle }}
        </span>
      </div>
    </slot>
    <div class="content">
      <slot name="filters"></slot>
      <slot name="action"></slot>
      <slot name="content" class="content"></slot>
    </div>
  </div>
  <div v-else class="content">
    <div
      class="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center py-2 text-center text-md-start text-oderco-super-dark"
    >
      <div class="h1 fw-bold align-self-center align-items-center mb-1 mb-md-0">
        {{ title }}
        <span class="text-secondary fw-normal" style="font-size: 16px">
          <span v-if="subtitle"> / </span>
          {{ subtitle }}
        </span>
      </div>
      <div class="mt-3 mt-md-0 ms-md-3 space-x-1">
        <img src="/assets/media/logo/ODEX-SOLAR.png" alt="Odex Logo" />
      </div>
    </div>
    <slot name="filters"></slot>
    <slot name="action"></slot>
    <slot name="content" class="content"></slot>
  </div>
</template>

<script lang="ts">
export default {
  name: "BaseLayout",
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
  },
};
</script>

<script lang="ts" setup>
import { useTemplateStore } from "../stores/template";

const store = useTemplateStore();
</script>
