import { createRouter, createWebHistory } from "vue-router";

import NProgress from "nprogress/nprogress.js";

import { useAuth } from "../stores/auth";
import { useShop } from "../stores/shop";
import { useTemplateStore } from "../stores/template";

import auth from "./auth";
import dashboard from "./dashboard";

import { useToast } from "vue-toastification";

const toast = useToast();

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
  },
  ...auth,
  ...dashboard,
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  linkExactActiveClass: "",
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
  routes,
});

NProgress.configure({ showSpinner: false });

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start();
  }

  next();
});

router.beforeEach((to, from, next) => {
  const publicPage = [
    "/auth/login",
    "/auth/registerStepOne",
    "/auth/registerStepTwo",
    "/auth/registerConfirmation",
    "/auth/reset-password/token",
    "/auth/forget-password",
    "/auth/email-confirmation",
    "/auth/confirmDelete",
  ];
  const authPage = !publicPage.includes(to.path);
  const authLocal = localStorage.getItem("token_auth");
  const permissionAdm = ["/dashboard/directsale/view"].includes(to.path);
  const authRole = localStorage.getItem("funcao");
  const storeTemplate = useTemplateStore();
  const shop = useShop();
  
  if (permissionAdm && authRole === "3") {
    toast.error("Você não tem permissão para acessar essa tela.");
    next("/dashboard/home");
  }

  storeTemplate.mobileSize();

  if (authPage && !authLocal) {
    localStorage.removeItem("token_auth");
    next("/auth/login");
  } else {
    next();
  }
 
  const urlAtual = to.path;
  const pasta = urlAtual.split("/");

  if(pasta[2] !== 'shop' || pasta[3] === 'customKit'){
    shop.filters.pc_premio = 0;
    shop.filters.pessoa_id = 0;
  }

});

router.beforeResolve((to, from, next) => {
  const auth = useAuth();
  const storeTemplate = useTemplateStore();
  const shop = useShop();

  const publicPage = [
    "/auth/login",
    "/auth/registerStepOne",
    "/auth/registerStepTwo",
    "/auth/registerConfirmation",
    "/auth/reset-password/token",
    "/auth/forget-password",
    "/auth/email-confirmation",
    "/auth/confirmDelete",
  ];
  const authPage = !publicPage.includes(to.path);

  const shopCart = [
    "/dashboard/shop/checkout/register",
    "/dashboard/shop/checkout/confirmation",
    "/dashboard/shop/checkout/payment",
    "/dashboard/shop/checkout/products",
  ];
  const pageShop = !shopCart.includes(to.path);

  const permissionAdm = ["/dashboard/directsale/view"].includes(to.path);
  const authRole = localStorage.getItem("funcao");

  if (permissionAdm && authRole === "3") {
    toast.error("Você não tem permissão para acessar essa tela.");
    next("/dashboard/home");
  }

  storeTemplate.mobileSize();

  if (authPage && !auth.verifyIfUserIsLoggedIn()) {
    next("/auth/login");
  } else {
    if (pageShop) {
      shop.rememberData = false;
      to.query.q !== "restoreShop"
        ? sessionStorage.removeItem("registerDataCustomer")
        : "";
      shop.registerData = [];
      shop.getProducts;
    } else {
      shop.rememberData = true;
    }
    next();
  }
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
