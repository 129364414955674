export default [
  {
    path: "/dashboard",
    component: () => import("@/layouts/variations/Main.vue"),
    redirect: "/dashboard/home",
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("@/views/dashboard/Home.vue"),
      },
      {
        path: "budgets",
        children: [
          {
            path: "view",
            name: "viewBudgets",
            component: () =>
              import("@/views/dashboard/budgets/BudgetsView.vue"),
          },
          {
            path: "view/:id",
            name: "viewBudget",
            component: () => import("@/views/dashboard/budgets/BudgetView.vue"),
          },
          {
            path: "new/",
            name: "newBudget",
            props: (route) => ({ query: route.query.q }),
            component: () => import("@/views/dashboard/budgets/NewBudget.vue"),
          },
        ],
      },
      {
        path: "clients",
        children: [
          {
            path: "view",
            name: "viewClients",
            component: () =>
              import("@/views/dashboard/clients/ClientsView.vue"),
          },
          {
            path: "view/:id",
            name: "viewClient",
            component: () => import("@/views/dashboard/clients/ClientView.vue"),
          },
          {
            path: "new",
            name: "newClient",
            props: (route) => ({ query: route.query.q }),
            component: () =>
              import("@/views/dashboard/clients/NewClientView.vue"),
          },
        ],
      },
      {
        path: "calculator",
        children: [
          {
            path: "calculator",
            name: "Calculator",
            component: () =>
              import("@/views/dashboard/calculator/Calculator.vue"),
          },
        ],
      },
      {
        path: "financing",
        children: [
          {
            path: "list",
            name: "FinancingList",
            component: () => import("@/views/dashboard/financing/List.vue"),
          },
          {
            path: "new",
            name: "FinancingNew",
            component: () => import("@/views/dashboard/financing/New.vue"),
          },
          {
            path: "proposal",
            name: "FinancingProposal",
            component: () => import("@/views/dashboard/financing/Proposal.vue"),
          },
          {
            path: "proposal/view/:id",
            name: "ViewProposal",
            component: () =>
              import("@/views/dashboard/financing/ProposalView.vue"),
          },
          {
            path: "proposalLosango",
            name: "ProposalLosango",
            component: () =>
              import("@/views/dashboard/financing/losango/ProposalLosango.vue"),
          },
          {
            path: "proposalLosango/view",
            name: "ProposalLosangoView",
            component: () =>
              import(
                "@/views/dashboard/financing/losango/ProposalViewLosango.vue"
              ),
          },
        ],
      },
      {
        path: "settings/users",
        children: [
          {
            path: "view",
            name: "viewUsers",
            component: () => import("@/views/dashboard/settings/UsersView.vue"),
          },
          {
            path: "new",
            name: "newUser",
            component: () =>
              import("@/views/dashboard/settings/NewUserView.vue"),
          },
          {
            path: "update/:id",
            name: "updateUser",
            component: () =>
              import("@/views/dashboard/settings/EditUserView.vue"),
          },
        ],
      },
      {
        path: "shop",
        redirect: "/dashboard/shop/view/panel",
        props: (route) => ({ query: route.query.q }),
        children: [
          {
            path: "view/structures",
            name: "viewStructuresProducts",
            component: () =>
              import(
                "@/views/dashboard/shop/Categories/StructuresProductsView.vue"
              ),
          },
          {
            path: "view/inverter",
            name: "viewInverterProducts",
            component: () =>
              import(
                "@/views/dashboard/shop/Categories/InverterProductsView.vue"
              ),
          },
          {
            path: "view/panel",
            name: "viewPanelProducts",
            component: () =>
              import("@/views/dashboard/shop/Categories/PanelProductsView.vue"),
          },
          {
            path: "view/search",
            name: "viewSearchProducts",
            component: () =>
              import("@/views/dashboard/shop/Categories/SearchView.vue"),
          },
          {
            path: "view/stringbox",
            name: "viewBoxProducts",
            component: () =>
              import("@/views/dashboard/shop/Categories/BoxProductsView.vue"),
          },
          {
            path: "view/:id",
            name: "viewProduct",
            component: () => import("@/views/dashboard/shop/ProductView.vue"),
          },
          {
            path: "checkout/register",
            name: "viewRegisterCustomer",
            props: (route) => ({ query: route.query.q }),
            component: () =>
              import("@/views/dashboard/shop/CheckoutCustomerView.vue"),
          },
          {
            path: "checkout/products",
            name: "viewProductsCheckout",
            component: () =>
              import("@/views/dashboard/shop/CheckoutProductsView.vue"),
          },
          {
            path: "checkout/payment",
            name: "viewPaymentCheckout",
            props: (route) => ({ query: route.query.q }),
            component: () =>
              import("@/views/dashboard/shop/CheckoutPaymentView.vue"),
          },
          {
            path: "checkout/confirmation",
            name: "viewOrderConfirmation",
            component: () =>
              import("@/views/dashboard/shop/OrderConfirmation.vue"),
          },
          {
            path: "customKit/:from?",
            children: [
              {
                path: "",
                name: "customKitIndex",
                component: () =>
                  import("@/views/dashboard/customKit/CustomIndex.vue"),
              },
              {
                path: "panel",
                name: "customKitPanel",
                component: () =>
                  import("@/views/dashboard/customKit/CustomPanel.vue"),
              },
              {
                path: "inverter",
                name: "customKitInverter",
                component: () =>
                  import("@/views/dashboard/customKit/CustomInverter.vue"),
              },
              {
                path: "box",
                name: "customKitBox",
                component: () =>
                  import("@/views/dashboard/customKit/CustomBox.vue"),
              },
              {
                path: "front",
                name: "customKitFrontBox",
                component: () =>
                  import("@/views/dashboard/customKit/CustomFrontBox.vue"),
              },
              {
                path: "structure",
                name: "customKitStructure",
                component: () =>
                  import("@/views/dashboard/customKit/CustomStructure.vue"),
              },
              {
                path: "accessories",
                name: "customKitAccessories",
                component: () =>
                  import("@/views/dashboard/customKit/CustomAccessories.vue"),
              },
              {
                path: "resume",
                name: "customKitResume",
                component: () =>
                  import("@/views/dashboard/customKit/CustomResume.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "pedidos",
        children: [
          {
            path: "view",
            name: "PedidosView",
            component: () =>
              import("@/views/dashboard/pedidos/PedidosView.vue"),
          },
          {
            path: "view/:cdFilial/:cdPedidoVenda",
            name: "viewPedido",
            component: () =>
              import("@/views/dashboard/pedidos/VisaoGeralPedido.vue"),
          },
          {
            path: "/relVendaDireta",
            name: "RelVendaDireta",
            component: () =>
              import("@/views/dashboard/pedidos/RelVendaDireta.vue"),
          },
          {
            path: "/relIntegrador",
            name: "relIntegrador",
            component: () =>
              import("@/views/dashboard/pedidos/RelIntegrador.vue"),
          },
        ],
      },
      {
        path: "directsale",
        children: [
          {
            path: "view",
            name: "directSaleIndex",
            component: () => import("@/views/dashboard/directSale/Index.vue"),
          },
          {
            path: "view/premio",
            name: "directSaleView",
            component: () =>
              import("@/views/dashboard/directSale/DirectSale.vue"),
          },
        ],
      },
      {
        path: "cart",
        name: "cartView",
        props: (route) => ({ query: route.query.q }),
        component: () => import("@/views/dashboard/shop/CartMobileView.vue"),
      },
      {
        path: "profile",
        name: "profile",
        component: () => import("@/views/dashboard/profile/Index.vue"),
      },
      {
        path: "reports",
        name: "viewReports",
        component: () => import("@/views/dashboard/reports/Index.vue"),
      },
      {
        path: "report",
        name: "viewReport",
        component: () => import("@/views/dashboard/reports/Report.vue"),
      },
    ],
  },
];
