import { defineStore } from "pinia";
import {
  catalogSearch,
  getPaymentLink,
  getStructureTypes,
  removeItemBudget,
  saveBudget,
  saveCustomerPf,
  saveCustomerPj,
  savePaymentLink,
} from "../services/api";

const state = () => ({
  products: {
    items: [],
  },
  registerData: {},
  rememberData: false,
  idUser: null,
  directSale: {},
  getAll: [],
  getOne: [],
  loading: true,
  filters: {
    limit: 21,
    offset: 1,
    filters: [{ type: "=", field: "tp_status", value: "R" }],
    pc_premio: 0,
    pessoa_id: 0,
  },
});

const actions = {
  reset() {
    localStorage.removeItem("products");
    this.products = {
      items: [],
    };
    this.registerData = {},
    this.rememberData = false,
    this.idUser = null,
    this.directSale = {},
    this.getAll = [],
    this.getOne = [],
    this.loading = true,
    this.filters = {
      limit: 21,
      offset: 1,
      filters: [{ type: "=", field: "tp_status", value: "R" }],
      pc_premio: 0,
      pessoa_id: 0,
    };
  },

  async getPaymentLink(data) {
    const response = await getPaymentLink(data);
    return response.data.status != 200 ? "error" : response.data.data;
  },

  async savePaymentLink(data) {
    const response = await savePaymentLink(data);
    return response.data;
  },

  async getStructureTypes() {
    const response = await getStructureTypes();
    return response.data;
  },

  async getResale() {
    try {
      const response = await catalogSearch(this.filters);

      const resale = await response.data.data;

      this.getAll = resale;
    } catch (error) {
      this.getAll = error.response.data;
    }
  },

  async getDirectSale(data) {
    this.filters.pessoa_id = data.pessoa_id;
    this.filters.pc_premio = data.pc_premio;
    const response = await catalogSearch(this.filters);

    const directSale = await response.data.data;

    this.getAll = directSale;
    this.getOne = directSale.data.items;
  },
  async getProductInfoBySku(sku: number[]) {
    const response = await catalogSearch({
      filters: [
        {
          type: "=",
          field: "sku",
          value: sku,
        },
      ],
      limit: 999,
    });

    return response.data.data.data.items;
  },

  async getOneProduct(value) {
    this.filters.offset = 1;
    this.filters.filters = [];
    this.filters.filters.push({
      type: "=",
      field: "sku",
      value: value,
    });

    const response = await catalogSearch(this.filters);

    const resale = await response.data.data.data.items;

    this.getOne = resale;
  },
  async addNewProduct(data, setProducts = true) {
    let remove = false;

    try {
      const response = await saveBudget(data);
      const dataProducts = response.data.data;

      if (setProducts) {
        this.products = dataProducts;
        localStorage.setItem("products", JSON.stringify(dataProducts));
        remove = true;
      }

      return dataProducts;
    } catch (error) {
      if (remove) this.products.items.pop();
      return Promise.reject(error);
    }
  },
  async removeProduct(id, sku) {
    if (this.products.items.length === 1) {
      this.products = {
        items: [],
      };
      localStorage.removeItem("products");
    }
    const response = await removeItemBudget(id, sku);
    const dataProducts = response.data.data;
    this.products = dataProducts;
    localStorage.setItem("products", JSON.stringify(dataProducts));
  },
  async addNewCustomer(element, role) {
    if (role === "pj") {
      const response = await saveCustomerPj(element);
      this.idUser = await response.data.data.id;
    } else {
      const response = await saveCustomerPf(element);
      this.idUser = await response.data.data.id;
    }

    const getData = sessionStorage.getItem("registerDataCustomer");

    if (getData) {
      this.registerData.push(getData);

      return;
    }

    if (this.registerData.length > 0) {
      this.registerData = [];
      sessionStorage.removeItem("registerDataCustomer");
      this.registerData.push(element);
      sessionStorage.setItem(
        "registerDataCustomer",
        JSON.stringify(this.registerData)
      );
    } else {
      this.registerData.push(element);
      sessionStorage.setItem(
        "registerDataCustomer",
        JSON.stringify(this.registerData)
      );
    }
  },
};

const getters = {
  getProducts() {
    if (this.products.items.length === 0 && localStorage.getItem("products")) {
      if (this.products) {
        if (this.products.tp_status === "C") {
          this.products = {
            items: [],
          };
          this.registerData = [];
          localStorage.removeItem("products");
          sessionStorage.removeItem("registerDataCustomer");

          return this.products;
        }
      }

      const products = JSON.parse(localStorage.getItem("products"));
      this.products = products;

      return this.products;
    } else {
      if (this.products.tp_status === "C") {
        this.products = {
          items: [],
        };
        this.registerData = [];
        localStorage.removeItem("products");
        sessionStorage.removeItem("registerDataCustomer");

        return this.products;
      }

      return this.products;
    }
  },
  getNewCustomer() {
    if (this.products.items.length === 0 && localStorage.getItem("products")) {
      const products = JSON.parse(localStorage.getItem("products"));
      this.products = products;
    }

    const getData = sessionStorage.getItem("registerDataCustomer");

    if (Object.keys(getData).length !== 0) {
      this.registerData.push(getData);

      const dataJson = this.registerData[0];

      return dataJson;
    }
    return getData;
  },
  getUserId() {
    const user = JSON.parse(sessionStorage.getItem("registerDataCustomer"));
    if (Object.keys(user).length !== 0) {
      this.idUser = user.id;
      return user.id;
    }
  },

  totalItems() {
    return this.products.items.length;
  },

  totalValue() {
    return this.products.vl_total;
  },

  totalProductsValue() {
    return this.products.items.reduce(
      (accumulator, currentValue) => accumulator + currentValue.vl_bruto,
      0
    );
  },

  totalIPIValue() {
    return this.products.items.reduce(
      (accumulator, currentValue) => accumulator + currentValue.vl_ipi,
      0
    );
  },

  totalValuePremioCalculate() {
    return this.products.items.reduce(
      (accumulator, currentValue) =>
        accumulator +
        currentValue.vl_bruto * (currentValue.vl_ipi ? 1.0975 : 1),
      0
    );
  },
};

export const useShop = defineStore("shop", {
  state,
  getters,
  actions,
  persist: true,
});
