import { createGtm } from "@gtm-support/vue-gtm";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { createApp } from "vue";
import App from "./App.vue";
// import { H } from "highlight.run";
import FloatingVue from "floating-vue";
import "vue3-simple-typeahead/dist/vue3-simple-typeahead.css";

// You can use the following starter router instead of the default one as a clean starting point
// import router from "./router/starter";
import router from "./router";

// Template components
import Base from "@/components/Base.vue";
import BaseBackground from "@/components/BaseBackground.vue";
import BaseBlock from "@/components/BaseBlock.vue";
import BasePageHeading from "@/components/BasePageHeading.vue";

// Template directives
import clickRipple from "@/directives/clickRipple";

// Bootstrap framework
import * as bootstrap from "bootstrap";
window.bootstrap = bootstrap;

// Plugins
import { DateTime } from "luxon";
import { OhVueIcon, addIcons } from "oh-vue-icons";
import {
  BiAwardFill,
  BiCheckLg,
  BiCircle,
  BiCircleFill,
  BiDownload,
  BiExclamationTriangle,
  BiFunnel,
  BiSearch,
  BiThreeDots,
  CoBrushAlt,
  CoCalendar,
  CoHamburgerMenu,
  CoLocationPin,
  CoSettings,
  FaRegularCalendarAlt,
  FaRegularTrashAlt,
  FaSearchPlus,
  FaSolarPanel,
  HiSolidPencilAlt,
  HiUpload,
  HiUserCircle,
  IoCopyOutline,
  IoExitOutline,
  LaCartArrowDownSolid,
  MdCalculate,
  MdKeyboardarrowright,
  MdKeyboarddoublearrowleft,
  MdKeyboarddoublearrowright,
  MdLogoutRound,
  MdNoteOutlined,
  MdPersonoutlineSharp,
  MdPersonsearchRound,
  MdSellRound,
  MdTimerOutlined,
  OiClock,
  OiListUnordered,
  PrArrowUpRight,
  PrEnvelope,
  PrPhone,
  RiErrorWarningLine,
  RiHomeFill,
} from "oh-vue-icons/icons";
import { Dataset } from "vue-dataset";
import decimal from "./utils/decimal";

import Maska from "maska";
import vfmPlugin from "vue-final-modal";
import "vue-select/dist/vue-select.css";
import Toast from "vue-toastification";
import cutText from "./utils/cutText";
import formatPhone from "./utils/formatPhone";

import "floating-vue/dist/style.css";

const options = {
  position: "bottom-right",
};

const DatasetLanguage = {
  ...Dataset,
  name: "DatasetLanguage",
  provide() {
    return {
      datasetI18n: {
        show: "Ver",
        entries: "Linhas",
        previous: "Anterior",
        next: "Proximo",
        showing: "Exibindo",
        showingTo: "para",
        showingOf: "de",
        showingEntries: "entradas",
      },
    };
  },
};

addIcons(
  FaSearchPlus,
  PrArrowUpRight,
  FaRegularTrashAlt,
  OiListUnordered,
  MdSellRound,
  CoSettings,
  MdCalculate,
  RiHomeFill,
  CoCalendar,
  PrEnvelope,
  MdNoteOutlined,
  PrPhone,
  LaCartArrowDownSolid,
  CoLocationPin,
  MdPersonsearchRound,
  MdLogoutRound,
  HiSolidPencilAlt,
  IoExitOutline,
  BiFunnel,
  BiSearch,
  IoCopyOutline,
  MdTimerOutlined,
  FaRegularCalendarAlt,
  HiUpload,
  OiClock,
  MdPersonoutlineSharp,
  BiAwardFill,
  CoHamburgerMenu,
  HiUserCircle,
  BiDownload,
  BiThreeDots,
  BiExclamationTriangle,
  RiErrorWarningLine,
  MdKeyboarddoublearrowleft,
  MdKeyboarddoublearrowright,
  MdKeyboardarrowright,
  CoBrushAlt,
  FaSolarPanel,
  BiCheckLg,
  BiCircleFill,
  BiCircle
);

// Craft new application
const app = createApp(App);
const pinia = createPinia();

pinia.use(piniaPluginPersistedstate);

// Register global components
app.component("BaseLayout", Base);
app.component("BaseBlock", BaseBlock);
app.component("BaseBackground", BaseBackground);
app.component("BasePageHeading", BasePageHeading);
app.component("VIcon", OhVueIcon);
app.component("DatasetL", DatasetLanguage);

// Register global directives
app.directive("click-ripple", clickRipple);

pinia.use(piniaPluginPersistedstate);

// Use Pinia and Vue Router
app.use(pinia);
app.use(router);
app.use(FloatingVue);

// Plugins
app.use(Toast, options);
app.use(Maska);
app.use(vfmPlugin);

app.use(
  createGtm({
    id: "GTM-MP28KLF",
    defer: false,
    compatibility: false,
    enabled: true,
    debug: false,
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: false,
  })
);

// if (import.meta.env.MODE == "production") {
//   H.init("3ej16wep", {
//     environment: "production",
//     version: "commit:abcdefg12345",
//     networkRecording: {
//       enabled: true,
//       recordHeadersAndBody: true,
//       urlBlocklist: [
//         "https://www.googleapis.com/identitytoolkit",
//         "https://securetoken.googleapis.com",
//       ],
//     },
//   });
// }

// Options
app.config.globalProperties.$luxon = DateTime;
app.config.globalProperties.$convertDecimal = decimal;
app.config.globalProperties.$formatPhone = formatPhone;
app.config.globalProperties.$cutText = cutText;

// ..and finally mount it!
app.mount("#app");
